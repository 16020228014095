/*
 * 업무구분 : 고객
 * 화 면 명 : MSPCM040M
 * 화면설명 : 고객정보 입력(지류동의)
 * 작 성 일 : 2022.11.28
 * 작 성 자 : sh.park2022
 *  
 * 휴대폰 인증 화면(MSPCM008D) 참고하여 개발
 */
<template>
  <ur-page-container class="msp" title="고객정보 입력" :show-title="true" type="subpage" :action-type="actionSlot" @on-header-left-click="fn_ClickPrev">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      
      <!-- Content 영역 ::start -->
      <mo-validate-watcher ref="vWatcher">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area">
          <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt30">
            <div class="info-title-wrap">
              <span class="info-title must">고객명</span>
            </div>
            <mo-text-field class="form-input-name full mb0" ref="custNmRef" :rules="validateRuleName" v-model="fn_CustNm" underline placeholder="고객명 입력 후 조회하기를 선택해주세요"
                      @keyup.enter="fn_CheckCustNm('enter')" maxlength="30" @blur="fn_CheckCustNm"/>
            <div class="ns-check mt6">
              <mo-checkbox v-if="custCardVO.isExistCustYN === 'Y'" class="is-checked" readonly>기존고객입니다.</mo-checkbox>
              <mo-checkbox v-if="custCardVO.isExistCustYN === 'N'" class="is-checked" readonly>신규고객입니다.</mo-checkbox>
            </div>
            <div class="ns-check mt6">
              <mo-checkbox class="fexTy5 crTy-bk7" v-model="fn_IsForeigner" :disabled="disabledObj.chkForeigner || custCardVO.isExistCustYN === 'Y'">외국인</mo-checkbox>
            </div>
            <div class="ns-btn-relative-area">
              <div class="ns-btn-relative-s">
                <div class="relative-div">
                  <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white ml0 mr0" @click="fn_CheckCustNm('enter')">조회하기</mo-button>
                </div>
              </div>
            </div>
          </ur-box-container>

          <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
            <div class="info-title-wrap mb10">
              <span class="info-title must">주민등록번호</span>
            </div>
            <div class="ns-btn-relative-area mt0 mb0">
              <div class="ns-btn-relative-s">
                <div class="relative-div">
                  <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white ml0 mr0"
                            @click="fn_OpenPop('MSPCM041P')" v-if="!isChnlCustScCd" :disabled="custCardVO.isExistCustYN === 'Y' && custCardVO.chnlCustId === ''">일련번호 조회</mo-button>
                </div>
              </div>
            </div>
            <div class="ns-check flex mb10">
              <mo-checkbox v-if="custCardVO.isExistCustYN !== 'Y' && !fn_IsForeigner" v-model="fn_IsChnlCustScCd" class="fexTy5 crTy-bk7">미확보</mo-checkbox>
            </div>
            <div class="verify-birth">
              <!-- 내국인 -->
              <mo-text-field v-if="!isForeigner" :rules="validateRuleKnbFrno" v-model="fn_KnbFrno" type="number" underline class="birth-input1 mb0" 
                              :disabled="!isChnlCustScCd" :placeholder="isChnlCustScCd ? '생년월일 8자리' : '생년월일 6자리'" mask="########"/>
              <!-- 외국인 -->
              <mo-text-field v-else :rules="validateRuleKnbFrno" v-model="fn_KnbFrno" type="number" underline class="birth-input1 mb0"
                              :disabled="!isChnlCustScCd" placeholder="외국인등록번호" mask="######" lazy/>
              <span :class="{'hyphen': !isChnlCustScCd}" class=" ml10 mr10"></span>
              
              <!-- 미확보: true / 뒷자리 입력 안받음(disabled) -->
              <mo-text-field v-if="!isChnlCustScCd" underline class="birth-input2 mb0" placeholder="*******" disabled/>
              <!-- 미확보: false -->
              <mo-checkbox-wrapper v-else class="w100">
                <div class="ns-radio-list fex-rw pt5">
                  <mo-radio v-model="rdoGender" value="1" class="mb0" :disabled="custCardVO.isExistCustYN === 'Y'">남</mo-radio>
                  <mo-radio v-model="rdoGender" value="2" class="mb0" :disabled="custCardVO.isExistCustYN === 'Y'">여</mo-radio>
                </div>
              </mo-checkbox-wrapper>
            </div>
          </ur-box-container>

          <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
            <div class="info-title-wrap mb10">
              <span class="info-title must">휴대폰번호</span>
            </div>
            <div class="verify-phone mb200">
              <!-- 휴대폰 앞자리-->
              <msp-bottom-select :items="phoneItems" v-model="fn_CelnoFrno" underline class="mr0 w100px" placeholder="010" bottom-title="" closeBtn
                                  :disabled="disabledObj.celno || (custCardVO.isExistCustYN === 'Y' && isPhoneConf)" />
              <!-- 휴대폰 뒷자리-->
              <mo-decimal-field numeric ref="celnoRef" :rules="validateRuleCelno" v-model="fn_CelnoBkno" mask="########" maxlength="8" underline placeholder="00000000" class="mb0 txt-center"
                                  @click="fn_BottomSheet" :disabled="disabledObj.celno" :readonly="isPhoneConf"/>

            </div>
          </ur-box-container>

          <!-- 이용동의 bottomSheet ::start -->
          <mo-bottom-sheet ref="agreeCnfrmPop" class="ns-bottom-sheet ns-style3">
            <div>
            <p class="cancel-ment" v-html="agreeMsg"></p>
            </div>
            <template v-slot:action>
              <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
                <div class="relative-div">
                  <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_AgreeCnfrmPop">확인</mo-button>
                </div>
              </ur-box-container>
            </template>
          </mo-bottom-sheet>
          <!-- 이용동의 bottomSheet ::end -->

          <!-- 기존고객 휴대폰번호 변경불가 안내 bottomSheet ::start -->
          <mo-bottom-sheet ref="existCustCnfrmPop" class="ns-bottom-sheet ns-style3">
            <div>
            <p class="cancel-ment">
              기존고객 휴대폰번호는 영업관리자의 동의 승인화면에서 변경할 수 있습니다.
              지류동의서를 관리자에게 제출하시기 바랍니다.
            </p>
            </div>
            <template v-slot:action>
              <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
                <div class="relative-div">
                  <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_existCustCnfrmPop">확인</mo-button>
                </div>
              </ur-box-container>
            </template>
          </mo-bottom-sheet>
          <!-- 기존고객 휴대폰번호 변경불가 안내 bottomSheet ::end -->

        </ur-box-container>
      </mo-validate-watcher>
      <!-- Content 영역 ::end -->

      <ur-box-container v-if="ShowBottomBtn" alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="chkNextBtn" @click="fn_Next">다음(상세정보 입력)</mo-button>
        </div>
      </ur-box-container>

    </ur-box-container>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
import moment from 'moment'
import Msg from '@/systems/webkit/msg/msg'
import cmConstants from '@/config/constants/cmConstants.js'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import MSPCM005P from '@/ui/cm/MSPCM005P' // 고객선택 
import MSPCM041P from '@/ui/cm/MSPCM041P' // 일련번호 조회 
import MSPCM044P from '@/ui/cm/MSPCM044P' // 동의서발행
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM040M',
  // 현재 화면 ID
  screenId: 'MSPCM040M',

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
  ***********************************************************************************/
  data () {
    return {
      title: '고객정보 입력', // 화면명
      preSrnId: '', // 타업무에서 진입시, 넘겨받은 진입한 화면ID

      validateRuleName: [ // 고객명 Rule 체크
        v => !!v || '필수 입력항목입니다',
        () => this.isCustNmChngRslt || '고객명은 수정할 수 없습니다.',
        () => this.custNmResult || this.custNmErrorMsg
      ],
      validateRuleKnbFrno: [ // 생년월일 Rule 체크
         v => !!v || '필수 입력항목입니다',
       v => this.isChnlCustScCd && v.length > 7 || '생년월일 8자리를 입력하세요.'
      ],
      validateRuleCelno: [ // 휴대번호 Rule 체크
        v => !!v || '필수 입력항목입니다',
        v => v.length > 7 || '휴대폰번호를 입력하세요.',
        () => this.chkCelnoResult || this.celnoErrorMsg
      ],

      actionSlot:{}, // 동의서 발행 (상단 버튼 Slot)
      lv_UserInfo: {}, // 사용자 정보
      isLoading: false, // 서비스 조회 Flag

      agreeMsg:'', //이용동의 안내 Message
      isCustNmChngRslt: true, // 고객명 입력값 Validation Result
      custNmResult: true, // 이름 유효성 체크 결과
      custNmErrorMsg: '', // 이름 유효성 결과 에러 메시지
      lv_CustNm: '', // 고객명 입력값

      isChnlCustScCd: false, // 미확보 체크여부
      rdoGender: '1', // 성별 (남자: 1, 여자: 2)
      isPhoneConf: true, // 휴대폰번호 alert형 바텀시트 확인여부
      // 고객 조회용 Service IF VO
      custCardRegMdfcVO: {},
      /* 주고객 정보 VO */
      custCardVO: {
        isExistCustYN:'',  //신규 여부
        custNm: '',        // 이름
        chnlCustId: '',    // 채널고객ID
        chnlCstgrId: '',   // 채널그룹ID
        knb: '',           // 전체주민번호
        knbBackup: '',     // 주민번호 백업 - TSSSP103M의 이전 버튼 선택 시, 마지막 등록된 주민번호값을 화면에 display해야 함.
        knbFrno: '',       // 주민번호 앞자리
        knbBkno: '',       // 주민번호 뒷자리
        knbBkFstNo: '',    // 주민번호 뒷자리 첫자리 (성별구분코드)
        knbBknoCipher: '', // 암호화된 주민번호
        knbScCd: 'ZPER01',        // 식별번호구분코드
        celno: '',      // 휴대전화 전체
        celnoFrno: {},  // 휴대전화 앞자리
        celnoBkno: '',  // 휴대전화 뒷자리
        // infoPrcusCnsntTypCd: '', // 정보활용동의 유형코드(17: 필수컨설팅, 19: 마케팅동의, 18: 둘다)
      },

      phoneItems: [],   //휴대폰 앞자리
      chkCelnoResult: false, // 핸드폰번호 유효성
      celnoErrorMsg: '', // 핸드폰번호 에러 메시지

      isForeigner: false, // 외국인 체크 여부

      disabledObj: {
        chkForeigner: false, // 외국인 입력 컴포넌트 - 초기진입 시 : 활성화
        knbFrno: false, // 생년월일, 외국인번호 입력 컴포넌트 - 초기진입 시 : 비활성화
        celno: true, // 휴대폰번호 입력 컴포넌트 - 초기진입 시 : 비활성화
      },

      chkNextBtn: true,
      ShowBottomBtn: true, // 키패드 노출시, 버튼 숨김

      // 팝업 연동
      popupObj: {}, // 팝업 Object (팝업 띄울때 사용)
    };
  },
  /***********************************************************************************
   * computed 정의 영역                                                              *
  ***********************************************************************************/
  computed: {
    // 고객명
    fn_CustNm: {
      get: function () {
        return this.lv_CustNm
      },
      set: function (data) {
        this.lv_CustNm = data ? data.trim().toUpperCase() : ''
        
        this.$refs.vWatcher.clearValidation()
      }
    },
    // 주민번호
    fn_CheckKnb () {
      let lv_KnbResult = ''

      this.$refs.vWatcher.clearValidation()
      
      if (this.isChnlCustScCd) { // 주민번호 미확보 체크 선택시에는 주민번호 앞자리만 필수 체크
        if (this.custCardVO.knbFrno !== '' && this.custCardVO.knbFrno.length === 6) {
          lv_KnbResult = this.custCardVO.knbFrno
        }
      } else {
        if (this.custCardVO.knbFrno !== '' && (this.custCardVO.knbBkno !== '' || this.custCardVO.knbBknoCipher !== '') &&
            this.custCardVO.knbFrno.length === 6) { // 주민번호 확보시에는 앞자리, 뒷자리 모두 입력되었는지 체크
          lv_KnbResult = this.custCardVO.knbFrno + this.custCardVO.knbBkno + this.custCardVO.knbBknoCipher
        }
      }
      return lv_KnbResult
    },
    // 외국인 체크박스
    fn_IsForeigner: {
      get: function () {
        return this.isForeigner
      },
      set: function (data) {
        this.isForeigner = data
        this.fn_KnbFrno = ''
        this.$refs.vWatcher.clearValidation()

        if (data) {
          this.custCardVO.knbScCd = 'ZPER02' // 외국인
        } else {
          this.custCardVO.knbScCd = 'ZPER01' // 내국인
        }
      }
    },
    // 미확보 체크박스
    fn_IsChnlCustScCd: {
      get: function () {
        return this.isChnlCustScCd
      },
      set: function (data) {
        this.isChnlCustScCd = data
        this.fn_KnbFrno = ''
        this.rdoGender = '1'
        this.$refs.vWatcher.clearValidation()
        this.fn_Validation()
      }
    },
    // 주민번호 앞자리
    fn_KnbFrno: { // 전체주민번호변수(custCardVO.knb)에 변경 값 처리
      get: function () {
        return this.custCardVO.knbFrno
      },
      set: function (data) {
        this.$refs.vWatcher.clearValidation()

        if (this.custCardVO.knb !== '' && this.custCardVO.knb.substr(0,6) !== data) {
          this.custCardVO.isKnbChanged = true
        } else {
          this.custCardVO.isKnbChanged = false
        }

        this.custCardVO.knbFrno = data
        this.fn_Validation()
      }
    },
    // 핸드폰번호 앞자리
    fn_CelnoFrno: { // 전체핸드폰번호변수(custCardVO.celno)에 변경 값 처리
      get: function () {
        return this.custCardVO.celnoFrno
      },
      set: function (data) {
        if (this.custCardVO.celnoFrno !== null && this.custCardRegMdfcVO.celnoFrno !== data) {
          this.custCardVO.isCelnoChanged = true
        } else {
          this.custCardVO.isCelnoChanged = false
        }

        this.custCardVO.celnoFrno = data

        if (this.custCardVO.isCelnoChanged) { // 신규이거나 기존 고객의 핸드폰번호 수정시에만 체크
          this.fn_CheckCelno()
        }
        this.fn_Validation()
      }
    },
    // 핸드폰번호 뒷자리
    fn_CelnoBkno: { // 전체핸드폰번호변수(custCardVO.celno)에 변경 값 처리
      get: function () {
        return this.custCardVO.celnoBkno
      },
      set: function (data) {
        this.$refs.vWatcher.clearValidation()

        if (this.custCardVO.celnoBkno !== '' && this.custCardRegMdfcVO.celnoBkno !== data) {
          this.custCardVO.isCelnoChanged = true
        } else {
          this.custCardVO.isCelnoChanged = false
        }

        this.custCardVO.celnoBkno = data

        this.chkCelnoResult = true
        if (this.custCardVO.isCelnoChanged) { // 신규이거나 기존 고객의 핸드폰번호 수정시에만 체크
          this.fn_CheckCelno()
        }
        this.fn_Validation()
      }
    },
  },
  watch: {
    "custCardVO.isExistCustYN"(newVal) {
      if (newVal === 'Y') {
        this.isPhoneConf = true;
      }
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
  ***********************************************************************************/
  created() {
    const lv_Vm = this
    
    let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
    window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt+1)

    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo

    // ************ 키패드 이벤트 등록 ************ //
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_SHOW, () => {
      // 키패드 노출시 처리
      lv_Vm.ShowBottomBtn = false
    })
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_HIDE, () => {
      // 키패드 숨김시 처리
      lv_Vm.ShowBottomBtn = true
    })

    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickPrev)
  },
  mounted() {
    const lv_Vm = this

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM040M')

    // 상단 검색, 카테고리 버튼 생성
    this.actionSlot = {
      actionComponent: {
        template:'<mo-button shape="border" class="ns-btn-round btn-title-style-1" @click="fn_WcnstPbl()">동의서발행</mo-button>',
        methods: {
          fn_WcnstPbl() {
            // MSPCM044P 호출
            lv_Vm.fn_OpenPop('MSPCM044P')
          }
        } // methods
      } // actionComponent
    } // actionSlot

    this.fn_Init()

    // 화면 진입시, 키패드 활성화
    this.$refs['custNmRef'].focus()
  },
  beforeDestroy() {
    // 키패드 이벤트 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_SHOW);
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_HIDE);

    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickPrev)
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                            *
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_Init
     * @notice   화면진입시, 최초 실행 함수
     *           초기 셋팅 및 설정
    *********************************************************/
    fn_Init () {
      const lv_Vm = this
      let lv_Item = {}

      if (!this.$bizUtil.isEmpty(this.$route.params.srnId)) {
        this.lv_CustNm = this.$route.params.custNm // 고객명
        this.preSrnId = this.$route.params.srnId // 고객등록 진입 전 화면ID
      }

      // 동의 문구 셋팅
      this.agreeMsg = this.$t('cm')['ECMC080'] // "입력한 휴대폰번호로 고객에게 알림톡이 발송되므로 정확한 번호 입력부탁드립니다.",

      // 휴대전화 앞자리 정보 세팅
      // let lv_CelphCD = lv_Vm.getStore('code').getters.getState.get('ZA_CELPH_KNB_CD')
      let defaultValu = {value: '0', text: '선택'}
      let lv_CelphCD = this.$bizUtil.cloneDeep(cmConstants.CELPH_KNB_CD)
      this.phoneItems = [defaultValu, ...lv_CelphCD]

      // 휴대폰번호 앞자리 초기값은 '010'
      lv_Vm.fn_CelnoFrno = '010'

      this.fn_KnbFrno = ''
      this.fn_CelnoBkno = ''
    },
    /*********************************************************
     * @function fn_CustCardInit
     * @notice   CustCardVO 셋팅 및 초기화
    *********************************************************/
    fn_CustCardInit () {
      this.custCardVO = {
        isExistCustYN:'N',  //신규 여부
        custNm: '',        // 이름
        chnlCustId: '',    // 채널고객ID
        chnlCstgrId: '',   // 채널그룹ID
        knb: '',           // 전체주민번호
        knbBackup: '',     // 주민번호 백업 - TSSSP103M의 이전 버튼 선택 시, 마지막 등록된 주민번호값을 화면에 display해야 함.
        knbFrno: '',       // 주민번호 앞자리
        knbBkno: '',       // 주민번호 뒷자리
        knbBkFstNo: '',    // 주민번호 뒷자리 첫자리 (성별구분코드)
        knbBknoCipher: '', // 암호화된 주민번호
        knbScCd: 'ZPER01',        // 식별번호구분코드
        celno: '',      // 휴대전화 전체
        celnoFrno: {},  // 휴대전화 앞자리
        celnoBkno: '',  // 휴대전화 뒷자리
      };

      this.fn_KnbFrno = ''
      this.fn_CelnoBkno = ''

      this.disabledObj.chkForeigner = false
      this.disabledObj.knbFrno = false
      this.disabledObj.celno = false

      // 휴대폰번호 앞자리 초기값은 '010'
      this.fn_CelnoFrno = this.phoneItems[1].value
    },
    /*********************************************************
     * @function fn_CheckCustNm
     * @notice   고객명 입력 후 focus out 될 때 체크 함수 호출
    *********************************************************/
    fn_CheckCustNm (param) {
      this.$refs.vWatcher.clearValidation()

      if (this.$bizUtil.isEmpty(this.lv_CustNm)) {
        return this.$refs.custNmRef.validate()
      }

      let lv_CustNmResult = this.lv_CustNm.trim().toUpperCase()
      let regexp_eng = '[a-zA-Z]' // 영문(대소)

      // 한글 10글자, 영문은 30글자 제한
      if (!this.$bizUtil.isEmpty(lv_CustNmResult)) {
        if (lv_CustNmResult.search(regexp_eng) > -1) { // 영문이 있으면
          lv_CustNmResult = lv_CustNmResult.substr(0, 30)
        } else {
          lv_CustNmResult = lv_CustNmResult.substr(0, 10)
        }
      }

      let custScCd = this.fn_IsForeigner ? 'ZPER02' : 'ZPER01'
      if (this.$bizUtil.isCustNameValidation(lv_CustNmResult, custScCd)) {
        this.custNmResult = true // 이름 유효성 체크 결과
        // 기존고객 조회 서비스
        if (param === 'enter') { // 엔터키 입력시에만 고객조회함
          this.fn_SearchCustomerInfo()
          return
        }
      } else {
        if (lv_CustNmResult.search(regexp_eng) > -1 && !this.fn_IsForeigner) { // 영문이 있으면
          this.custNmResult = false // 이름 유효성 체크 결과
          this.custNmErrorMsg = '외국인 체크를 해주세요'
          this.$refs.custNmRef.validate()
        } else {
          this.custNmResult = false // 이름 유효성 체크 결과
          this.custNmErrorMsg = '유효하지 않은 고객명입니다. 정확한 정보를 확인하시기 바랍니다.(숫자,영문, 특수문자 등)'
          this.$refs.custNmRef.validate()
        }
      }
      this.lv_CustNm = lv_CustNmResult
      return this.custNmResult
    },
    /*********************************************************
     * @function fn_OpenPop
     * @notice   팝업 Open
     *               - 고객 조회(MSPCM005P)
     *               - 일련번호 조회(MSPCM041P)
     *               - 동의서 발행(MSPCM044P)
     * @param    srnId: Screen ID
    *********************************************************/
    fn_OpenPop (srnId) {
      const lv_Vm = this
      
      let popComp = '' // 오픈시킬 팝업
      let pProp = {}      // 팝업 Props
      let listeners = {}  // emit Listeners

      // 고객 조회(사랑온 1차 화면)
      if (srnId === 'MSPCM005P') {
        popComp = MSPCM005P
        pProp = {
          pCustNm: lv_Vm.lv_CustNm.trim() // 고객명
        }
        
        listeners = {
          confirmPopup: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.popupObj)

            if (pData.isExistCustYN !== 'N') {
              // 파라미터 세팅
              lv_Vm.$refs.vWatcher.clearValidation()
              lv_Vm.fn_ConfirmMSPCM005P(pData.custCardVO)

            } else {
              lv_Vm.custCardVO.isExistCustYN = pData.isExistCustYN
              lv_Vm.fn_CustCardInit()
            }
            lv_Vm.custCardVO.custNm = lv_Vm.lv_CustNm
          }
        };

      // 일련번호 조회
      } else if (srnId === 'MSPCM041P') {
        if (this.$bizUtil.isEmpty(this.custCardVO.custNm) && this.$bizUtil.isEmpty(this.custCardVO.isExistCustYN)) {
          let alertMsg = '고객을 먼저 조회해주세요.'
          this.fn_OpenAlert(alertMsg, this.$refs['custNmRef'])
          return
        }
        popComp = MSPCM041P
        pProp = {
          pCustInfo: lv_Vm.custCardVO // 주고객VO
        }
        listeners = {
          onPopupConfirm: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.popupObj)
            
            if (!this.$bizUtil.isEmpty(pData.rrn)) {
              lv_Vm.custCardVO.knb = pData.rrn.split('-').join('')
              lv_Vm.custCardVO.knbBackup = pData.rrn.split('-').join('')
              lv_Vm.fn_KnbFrno = pData.rrn.substr(0,6)
              lv_Vm.custCardVO.knbBkno = pData.rrn.substr(7,1) + '******'

              // 임시고객일 경우, 가망고객으로 변경
              if (lv_Vm.custCardVO.chnlCustScCd === '02') {
                lv_Vm.custCardVO.chnlCustScCd = '01'
                lv_Vm.custCardVO.isKnbChanged = true
              }
            }

            lv_Vm.custCardVO.cnsltWcnstPblNo = pData.cnsltWcnstPblNo
            lv_Vm.custCardVO.sno = pData.sno
            lv_Vm.fn_CheckCelno()
            lv_Vm.fn_Validation()

            // 휴대번호 포커스
            if (this.$bizUtil.isEmpty(lv_Vm.custCardVO.celnoBkno)) {
              this.fn_BottomSheet()
            }
          }
        };

      // 동의서 발행
      } else if (srnId === 'MSPCM044P') {
        this.custCardVO.custNm = this.custCardVO.custNm.toUpperCase().trim()
        
        popComp = MSPCM044P
        pProp = {
          pCustCardVO: lv_Vm.custCardVO // 주고객VO
        }
        
        listeners = {
          onPopupConfirm: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.popupObj)
            console.log(pData)
          },
          onPopupCancel: () => {
            lv_Vm.$bottomModal.close(lv_Vm.popupObj)
          }
        };
      }

      // 팝업창 띄우기
      this.popupObj = lv_Vm.$bottomModal.open(popComp, {
        properties: pProp,
        listeners: listeners
      });

    },
    /******************************************************************************
     * @function fn_SearchCustomerInfo
     * @name     정보활용동의_정보_조회
    ******************************************************************************/
    fn_SearchCustomerInfo () {
      const lv_Vm = this

      lv_Vm.custCardVO.isExistCustYN = '' // 기존/신규 여부 초기화

      const validOnlyEnglish = (s) => !/(?=.*[^\w\s])/.test(s)
      lv_Vm.lv_FilterSearchCustNm = lv_Vm.custCardVO.custNm
      if (validOnlyEnglish(lv_Vm.lv_FilterSearchCustNm)) {
        lv_Vm.custCardVO.custNm = lv_Vm.lv_FilterSearchCustNm.toUpperCase()
      }

      let pParams = {
        custNm: lv_Vm.lv_CustNm.trim() // 고객명
      }

      const trnstId = 'txTSSCM10S4'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          // 필수 컨설팅 리스트
          if (res.body !== null && res.body.cMInfoPrcusCnsntListVO !== null) {
              lv_Vm.items = res.body.cMInfoPrcusCnsntListVO
              if (lv_Vm.items.length === 0) {
                lv_Vm.disabledObj.knbFrno = false // 생년월일, 외국인 입력 컴포넌트 활성화
                lv_Vm.disabledObj.chkForeigner = false
                lv_Vm.disabledObj.celno = false // 휴대폰번호 입력 컴포넌트 활성화

                // 고객카드VO 초기화
                lv_Vm.fn_CustCardInit()
                lv_Vm.custCardVO.isExistCustYN = 'N'
                lv_Vm.custCardVO.custNm = res.body.custNm
              } else if (lv_Vm.items.length > 0) {
                // 고객 선택 팝업 Open
                lv_Vm.fn_OpenPop('MSPCM005P')
              }
          } else {
            lv_Vm.disabledObj.knbFrno = false // 생년월일, 외국인 입력 컴포넌트 활성화
            lv_Vm.disabledObj.chkForeigner = false
            lv_Vm.disabledObj.celno = false // 휴대폰번호 입력 컴포넌트 활성화

            // 고객카드VO 초기화
            lv_Vm.fn_CustCardInit()
            lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /******************************************************************************
     * @function fn_CheckCelno
     * @name     핸드폰번호_유효성_체크
    ******************************************************************************/
    fn_CheckCelno () {
      this.chkCelnoResult = true
      let lv_Celno = this.fn_CelnoFrno + this.fn_CelnoBkno

      if (this.custCardVO.celnoFrno === '0') { // 선택일 경우 무조건 에러 처리
        this.chkCelnoResult = false
        this.celnoErrorMsg = '휴대폰 번호를 정확히 입력하여 주세요'
      } else if (this.custCardVO.celnoFrno === '010') { // 010 번호인 경우 전체 자리수는 무조건 11자리
        if (lv_Celno.length < 11) {
          this.chkCelnoResult = false
          this.celnoErrorMsg = '휴대폰 번호를 정확히 입력하여 주세요'
        } else if (lv_Celno.length === 11) {
          // 임시 휴대전화 등록 및 FC 번호와 동일한지 여부 체크
          this.fn_SelCelnoCheck(lv_Celno)
        }
      } else {
        if (lv_Celno.length < 8) { // 010 외에는 뒷자리가 7자리(xxx-xxxx)로 올수도 있음. 전체 자리수를 10자리를 max로 해서 체크
          this.chkCelnoResult = false
          this.celnoErrorMsg = '휴대폰 번호를 정확히 입력하여 주세요'
        } else {
          // 임시 휴대전화 등록 및 FC 번호와 동일한지 여부 체크
          this.fn_SelCelnoCheck(lv_Celno)
        }
      }
    },
    /******************************************************************************
     * @function fn_SelCelnoCheck
     * @notice   핸드폰번호 유효성 체크(임시 번호, FC와 동일번호 체크)
    ******************************************************************************/
    fn_SelCelnoCheck (paramCelno) {
      const lv_Vm = this

      let lv_CustCelno = this.custCardVO.celno.split('-').join('').trim()
      if (paramCelno !== undefined && paramCelno !== null && paramCelno !== '') { // created 시점의 임시번호 호출
        lv_CustCelno = paramCelno
      }
      if (lv_CustCelno === '01000000000' || lv_CustCelno === '0100000000' || lv_CustCelno === '01010000000' || lv_CustCelno === '01020000000' || lv_CustCelno === '01030000000') {
        this.chkCelnoResult = false
        this.celnoErrorMsg = '임의번호는 입력할 수 없습니다.(예: 010-xxxx-0000, 010-xxxx-1111 등) 다시 확인 후 입력 바랍니다.'
        return
      }

      if (this.isLoading) return
      if ((paramCelno !== undefined && paramCelno !== null && paramCelno !== '') || (this.custCardVO.isCelnoChanged && this.custCardVO.unAprvCustYn === 'N')) { // 임시번호 호출이나 기존 승인고객의 핸드폰변경이 있는경우 유효성 체크
        // 고객 VO복사
        let lv_CustCardVO = Object.assign({}, lv_Vm.custCardVO)

        // 미승인 고객이라면 휴대폰 체크 안함
        if (lv_CustCardVO.unAprvCustYn === 'Y') {
          let alertMsg = '이미 승인요청 된 미승인고객입니다.'
          this.fn_OpenAlert(alertMsg, null, () => this.$router.push({name: 'MSPCM220M'}))
          return
        }

        if (this.$commonUtil.isMobile()) {
          if (lv_CustCardVO.knbBknoCipher.length < 8) { // 모바일이고, 주민번호 변경 안한 케이스
            if (lv_CustCardVO.knbBknoCipher !== '1000000' && lv_CustCardVO.knbBknoCipher !== '2000000' &&
              lv_CustCardVO.knbBknoCipher !== '3000000' && lv_CustCardVO.knbBknoCipher !== '4000000') { // 주민번호 확보 고객은 knb 제거
              lv_CustCardVO.knb = ''
            }
            lv_CustCardVO.knbBknoCipher = '' // 주민번호 미변경
          }
        }

        lv_CustCardVO.celno = lv_CustCelno

        let pParams = lv_CustCardVO

        this.isLoading = true
        const trnstId = 'txTSSCM10S3'
        const auth = 'S'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(res => {
            if (res.body !== null) {
              // 2023.06.17 사용자가 본인을 고객으로 등록할수 있으므로 '02' 코드 정상 처리
              if (res.body.celnoStatCo === '00' || res.body.celnoStatCo === '02') { // 정상일 경우
                lv_Vm.chkCelnoResult = true
              } else {
                if (res.body.celnoStatCo === '01') { // 01: 임시등록 번호 (*화면에서 1차로 filtering함. 현재는 사용안함.)
                  lv_Vm.chkCelnoResult = false
                  lv_Vm.celnoErrorMsg = '임의번호는 입력할 수 없습니다.(예: 010-xxxx-0000, 010-xxxx-1111 등) 다시 확인 후 입력 바랍니다.'
                }
                // 2023.06.17 사용자가 본인을 고객으로 등록할수 있으므로 삭제(황고은 프로)
                //  else if (response.body.celnoStatCo === '02') { // 02: FC휴대번호와 동일한 경우
                //   lv_Vm.chkCelnoResult = false
                //   lv_Vm.celnoErrorMsg = '컨설턴트 핸드폰번호로 등록된 번호입니다.'
                // }
              }

              this.$nextTick(_ => {
                lv_Vm.fn_Validation()
              })

            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
            }
            lv_Vm.isLoading = false
          })
          .catch(error => {
            window.vue.error(error)
            lv_Vm.isLoading = false
          });

      }
    },
    /******************************************************************************
     * @function fn_ConfirmMSPCM005P
     * @notice   고객검색 팝업창에서 확인 버튼 클릭 시 호출.
     *            - 팝업창 닫고, 팝업에서 넘어온 파라미터로 화면 셋팅
    ******************************************************************************/
    fn_ConfirmMSPCM005P (param) {
      if (_.isEmpty(param))  return

      let lv_CheckAge = true
      let lv_Msg = ''

      // 고객명 세팅
      this.lv_CustNm = param.custNm
      
      // 미성년자 여부 체크 (2023.06.19 14세미만 체크 로직 제거)
      if (false) {
        // 만 14세이상 체크
        lv_CheckAge = this.fn_GetCurrentAge(param.birthNo)

        if (lv_CheckAge === false) {
          lv_Msg = '미성년 자녀를 친권자가 대리 동의할 경우, 대리인(친권자)을 조회하여 선택하시기 바랍니다.\n'
          lv_Msg += '대리인은 고객카드 상 미성년 자녀와 동일세대로 구성되어 있으며 세대 관계가 본인 또는 배우자인 경우로 한정됩니다.\n\n'
          lv_Msg += '※ 친권자의 대리 동의가 가능한 미성년 자녀는 만 14세 미만의 자녀입니다.'
        }
      }

      // 태아 체크
      if (lv_CheckAge) { // 만 14세 이상인 경우 // 태아가 아닌 경우
        this.fn_SelListInfoPrcusCnsntCustDetail(param)
      } else {
        if (!this.$bizUtil.isEmpty(lv_Msg)) {
          this.getStore('confirm').dispatch('ADD', lv_Msg)
        }
      }

      if (param.isExistCustYN === 'Y') { // 기존고객일 경우
        if (param.unAprvCustYn === 'Y') { // 미승인 고객일 경우
          this.disabledObj.chkForeigner = true
          this.disabledObj.knbFrno = true
          this.disabledObj.celno = true

          this.chkNextBtn = true
        } else { // 승인 고객일 경우
          this.pblCustScCd = '02' // 발행팝업의 고객카드 여부

          this.disabledObj.chkForeigner = true
          this.disabledObj.knbFrno = true
          this.disabledObj.celno = false

          this.chkNextBtn = false
        }

        this.custCardVO.isExistCustYN = param.isExistCustYN
        this.custCardVO.knb = param.knb

        this.fn_KnbFrno = param.rrnFrnoNo
        this.custCardVO.knbBkno = param.knb.substr(6)
        this.custCardVO.knbBknoCipher = param.knb.substr(6)
        this.custCardVO.knbBkFstNo = param.knb.substring(6, 7) // 주민번호 뒷7자리 중 첫째자리 추출

        // 휴대번호 셋팅
        let celNoObj = param.celno.split('-')

        if (!_.isEmpty(celNoObj) && celNoObj.length > 2) {
          this.fn_CelnoFrno = celNoObj[0]
          this.fn_CelnoBkno = celNoObj[1] + celNoObj[2]
        } else {
          this.fn_CelnoFrno = this.phoneItems[1].value
        }

        this.custCardVO.unAprvCustYn = param.unAprvCustYn

        /* 
          * infoPrcusMcnstYn: 필컨, infoPrcusMktYn: 마동
          * 필컨만 17, 마동만 19, 둘다 18
          */
        if (param.infoPrcusMcnstYn === 'Y') {
          if (param.infoPrcusMktYn === 'Y') { // 둘다
            this.custCardVO.infoPrcusCnsntTypCd = '18'
          } else { // 필컨만
            this.custCardVO.infoPrcusCnsntTypCd = '17'
          }
        } else {
          if (param.infoPrcusMktYn === 'Y') { // 마동만
            this.custCardVO.infoPrcusCnsntTypCd = '19'
          }
        }
      } else { // 초기화(기존고객을 선택했는데, isExistCustYN값이 N일때)

        // 고객카드VO 초기화
        this.fn_CustCardInit()
      }
    },
    /******************************************************************************
     * @function fn_GetCurrentAge
     * @notice   만 14세 체크 유틸
    ******************************************************************************/
    fn_GetCurrentAge (brithDay) {
      let lv_ReturnVal = true

      let standardAge = 14
      let lv_today = moment(new Date().toISOString()).format('YYYYMMDD')

      let lv_SubtractYear = moment(lv_today).subtract(standardAge, 'years').format('YYYYMMDD')

      let lv_BirthDay = parseInt(brithDay)
      lv_SubtractYear = parseInt(lv_SubtractYear)

      if (lv_SubtractYear >= lv_BirthDay) {
        lv_ReturnVal = true // 기준나이보다 크다. 즉 만 14세 이상
      } else {
        lv_ReturnVal = false // 기준나이보다 작다. 즉 만 14세 미만
      }

      return lv_ReturnVal
    },
    /******************************************************************************
     * @function fn_SelListInfoPrcusCnsntCustDetail
     * @name     정보활용동의_고객_상세조회
     * @notice   1. 고객 검색 팝업 조회 close 후 호출
     *           2. 타 메뉴에서 호출
     * 주요 파라미터   : 고객 명 / 채널그룹ID / 채널고객ID
     * @param    MSPCM005P.custCardVO (고객 조회해서 선택한 custVO)
    ******************************************************************************/
    fn_SelListInfoPrcusCnsntCustDetail (param) {
      const lv_Vm = this

      lv_Vm.custCardVO.custNm = param.custNm
      lv_Vm.custCardVO.chnlCustId = param.chnlCustId
      lv_Vm.custCardVO.chnlCstgrId = param.chnlCstgrId

      let pParams = {
        custNm: lv_Vm.custCardVO.custNm, // 고객명
        chnlCstgrId: lv_Vm.custCardVO.chnlCstgrId, // 채널그룹ID
        chnlCustId: lv_Vm.custCardVO.chnlCustId // 채널고객ID
      }

      const trnstId = 'txTSSCM10S5'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          // 서버 데이터 가져오기
          let tmpAprType = []

          // 정보활용동의 리스트
          if (res.body !== null && res.body.iCCustInfoPrcusCnsntVO !== null) {

            tmpAprType = res.body.iCCustInfoPrcusCnsntVO
            lv_Vm.custCardVO.iCCustInfoPrcusCnsntVO = tmpAprType // 전체 정보동의 활용 리스트를 custCardVO의 iCCustInfoPrcusCnsntVO에 저장함.

            // 본인 항목만 추출해서 custApvrList 변수에 저장
            // 주고객이 본인인지 자녀인지 여부 체크 - 필컨, 마동 만료일자 및 미승인 처리 상태의 값은 조회한 고객의 정보로 처리해야 함.
            // 만약 자녀를 주고객으로 조회했으면 this.custCardVO.custApvrList를 자녀의 동의 정보로 변경해야 함.
            lv_Vm.custCardVO.custApvrList = tmpAprType.filter(item => { // 검색한 본인 항목만 추출
                if (item.chnlCustId === lv_Vm.custCardVO.chnlCustId) {
                  lv_Vm.custCardVO.chnlCustScCd = item.chnlCustScCd
                  return item
                }
              });

            // 자녀 세대원 항목만 추출해서 hoshApvrList 변수에 저장
            lv_Vm.hoshApvrList = tmpAprType.filter(value => { // 본인, 자녀인 항목만 추출
                if (value.mnCustRltnCd === '03') {
                  return value
                }
              });

            lv_Vm.custCardVO.custNmOrigin = lv_Vm.custCardVO.custNm // 조회된 고객명을 원본값으로 가지고 있음. 고객명 변경 시 이름 체크하기 위해

            let lv_CustApvrList = lv_Vm.custCardVO.custApvrList

            if (!_.isEmpty(lv_CustApvrList)) {
              lv_Vm.custCardVO.unAprvCustYn = lv_CustApvrList[0].unAprvCustYn

              // 미승인 고객인지 체크
              // lv_Vm.fn_SetUnAprvCust()
              if (lv_Vm.custCardVO.unAprvCustYn !== 'Y') { // 미승인 고객이면 '동의유형선택'을 제외한 나머지 컴포넌트 비활성화처리하는 fn_SetUnAprvCust() 함수 호출
                // 고객 카드 조회 서비스 호출
                lv_Vm.fn_SelCustCardInfo()
              }
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /******************************************************************************
     * @function fn_SetDisabledComponent
     * @notice   기존 고객 조회 시 화면 component disabled / enabled 처리
    ******************************************************************************/
    fn_SetDisabledComponent () {
      if (this.custCardVO.isExistCustYN === 'Y') {
        // 수정 가능 항목 활성화 처리 (휴대폰 번호)
        this.disabledObj.celno = false

        // 수정 불가 항목 비 활성화 처리
        this.disabledObj.chkForeigner = this.disabledObj.knbFrno = true

        if (this.custCardVO.chnlCustScCd === '02') { // 주민번호 미확보 고객인 경우
          this.fn_IsChnlCustScCd = true
        } else {
          this.fn_IsChnlCustScCd = false
        }
      }
    },
    /******************************************************************************
     * @function fn_SetUnAprvCust
     * @notice   미승인 고객 화면 정보 세팅
    ******************************************************************************/
    fn_SetUnAprvCust () {
      // 초기화
      this.chkNextBtn = true
      this.disabledObj.chkForeigner = false
      this.disabledObj.knbFrno = true
      this.disabledObj.celno = true

      if (this.custCardVO.unAprvCustYn === 'Y') {
        this.chkNextBtn = true
        this.disabledObj.chkForeigner = true
        this.disabledObj.knbFrno = true
        this.disabledObj.celno = true
      }

      // 정보 세팅
      if (!_.isEmpty(this.custCardVO.custApvrList)) {
        let lv_CustInfo = this.custCardVO.custApvrList[0]

        this.custCardVO.chnlCustId = lv_CustInfo.chnlCustId // 채널고객ID
        this.custCardVO.chnlCstgrId = lv_CustInfo.chnlCstgrId // 채널그룹ID
        this.custCardVO.chnlCustScCd = lv_CustInfo.chnlCustScCd // 고객 상태 코드
        this.custCardVO.custNm = lv_CustInfo.custNm // 고객명
        this.custCardVO.knbBknoCipher = lv_CustInfo.knbEncr.substring(6) // 주민번호 뒷자리
      }
    },
    /******************************************************************************
     * @function fn_SelCustCardInfo
     * @name     고객_카드_조회
    ******************************************************************************/
    fn_SelCustCardInfo () {
      const lv_Vm = this

      let pParams = {
        chnlCustId: lv_Vm.custCardVO.chnlCustId, // 채널고객ID
        cnsltNo: this.lv_UserInfo.userId // 컨설턴트 번호
      }

      const trnstId = 'txTSSCM11S1'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (res.body !== null) {
            lv_Vm.custCardRegMdfcVO = res.body
            lv_Vm.disabledObj.knbFrno = true // 생년월일, 외국인 입력 컴포넌트 비활성화
            lv_Vm.disabledObj.chkForeigner = true
            lv_Vm.disabledObj.celno = false // 휴대폰번호 입력 컴포넌트 활성화

            // 고객 카드 조회 결과를 화면 VO에 bind하는 함수 호출
            lv_Vm.fn_BindCustCardVO()
          } else { // 조회 결과가 없는 경우
            lv_Vm.disabledObj.knbFrno = false // 생년월일, 외국인 입력 컴포넌트 활성화
            lv_Vm.disabledObj.chkForeigner = false
            lv_Vm.disabledObj.celno = false // 휴대폰번호 입력 컴포넌트 활성화

            if (res.msgComm.msgCd === 'EEAC928') {
              lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('co')['ECOC009']) // '조회하고자 하는 데이터가 없습니다'
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
            }
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /******************************************************************************
     * @function fn_BindCustCardVO
     * @notice   txTSSCM11S1 호출 후, 고객 카드 조회 서비스 결과를
     *              화면 VO(custCardVO)에 bind
    ******************************************************************************/
    fn_BindCustCardVO () {
      const lv_Vm = this

      for (let key in this.custCardRegMdfcVO) {
        this.custCardVO[key] = this.custCardRegMdfcVO[key]
      }

      this.custCardVO.custNmPrev = this.custCardVO.custNm

      this.custCardVO.knbFrno = this.custCardRegMdfcVO.knb.substring(0, 6) // 주민번호 앞자리

      if (this.$commonUtil.isMobile()) {
        this.custCardVO.knbBknoCipher = this.custCardRegMdfcVO.knb.substring(6) // 주민번호 뒷자리
        // this.$refs.socialPostNum.$data.txtField = this.custCardVO.knbBknoCipher
      } else {
        this.custCardVO.knbBkno = this.custCardRegMdfcVO.knb.substring(6) // 주민번호 뒷자리
      }
      this.custCardVO.knbBackup = this.custCardRegMdfcVO.knb // 주민번호 백업

      if (this.custCardVO.knbScCd === 'ZPER02') {
        this.isForeigner = true
      } else if (this.custCardVO.knbScCd === 'ZPER01') {
        this.isForeigner = false
      }

      let tmpOwnApv = this.custCardVO.custApvrList // 주고객 정보활용동의 리스트

      /*----------- 동의유형선택 / 미승인 여부 체크 세팅 start -----------*/
      // 동의 유형 - 만료일 세팅
      if (tmpOwnApv != null && tmpOwnApv.length > 0) {
        if (tmpOwnApv[0].infoPrcusCnsntYmd14 !== '' && tmpOwnApv[0].infoPrcusCnsntYmd14 !== '00000000') { // 필수 컨설팅 동의
          /* // 필수컨설팅 만료일은 안보여주는 것으로 - 마케팅 만료일자를 가져오는 로직이 정리된 후 주석제거함. - 현재는 주석 처리함.
          this.custCardVO.mcnstYmd = this.$commonUtil.dateDashFormat(tmpOwnApv[0].infoPrcusCnsntYmd14)
          this.custCardVO.chkMcnstNm = `${this.custCardVO.chkMcnstNm} (${this.custCardVO.mcnstYmd} 만료예정)`
          */
        }

        if (tmpOwnApv[0].infoPrcusCnsntYmd12 !== '' && tmpOwnApv[0].infoPrcusCnsntYmd12 !== '00000000') { // 마케팅 동의
          this.custCardVO.mktYmd = this.$commonUtil.dateDashFormat(tmpOwnApv[0].infoPrcusCnsntYmd12)
          /* // 마케팅 만료일자를 BPID 기준으로 가져오도록 변경이 된 후 만료일 보여주는 것으로. - 현재는 주석 처리함.
          this.custCardVO.chkMktNm = `${this.custCardVO.chkMktNm} (${this.custCardVO.mktYmd} 만료예정)`
          */
        }

        this.custCardVO.unAprvCustYn = (tmpOwnApv[0].unAprvCustYn !== null) ? tmpOwnApv[0].unAprvCustYn : '' // 미승인 상태 값 처리 - Y: 미승인, N: 승인
      }
      /*----------- 동의유형선택 세팅 / 미승인 여부 체크 end -----------*/

      /*----------- 휴대전화 세팅 start -----------*/
      if (this.custCardRegMdfcVO.celno !== null && this.custCardRegMdfcVO.celno.trim() !== '') {
        let lv_CelSplit = this.$cmUtil.replacePhoneNumWithHyphen(this.custCardRegMdfcVO.celno)
        this.custCardVO.celnoObj = lv_CelSplit.split('-')
        this.custCardRegMdfcVO.celnoObj = lv_CelSplit.split('-')

        if (!_.isEmpty(this.custCardVO.celnoObj)) {
          this.chkCelnoResult = true
          
          this.custCardRegMdfcVO.celnoFrno = this.custCardVO.celnoObj[0]
          this.fn_CelnoFrno = this.custCardVO.celnoObj[0]
          this.custCardRegMdfcVO.celnoBkno = this.custCardVO.celnoObj[1] + this.custCardVO.celnoObj[2]
          this.fn_CelnoBkno = this.custCardVO.celnoObj[1] + this.custCardVO.celnoObj[2]
        }
      }
      /*----------- 휴대전화 세팅 end -----------*/

      // 세대원 리스트 매핑 - 자녀만 카드리스트에 포함됨
      if (this.custCardRegMdfcVO.iCHoshInfoListInqrVO !== null && this.custCardRegMdfcVO.iCHoshInfoListInqrVO.length > 0) {
        this.existHoshInfoListInqrVO = this.custCardRegMdfcVO.iCHoshInfoListInqrVO.filter(value => {
            if (value.mnCustRltnCd === '03') {
              return value
            }
          });

        this.existHoshInfoListInqrVO.forEach(item => { // 필수컨설팅, 마케팅 완료일, iCCustInfoPrcusCnsntVO 초기화
            item.isExistCustYN = 'Y'
            item.prcsfCd = 'U'
            item.mcnstYmd = ''
            item.mktYmd = ''
            item.iCCustInfoPrcusCnsntVO = {}
            item.agreeTypeMcnst = false // 필수컨설팅 체크
            item.agreeTypeMkt = false // 마케팅동의 체크
            item.isPossibleFetus = false
            item.isKnbChanged = false
            item.isCustNmChanged = false
            item.isCelnoChanged = false
            item.isCustJobCdChanged = false
            item.isNew = false
            item.knbFrno = item.knb.substring(0, 6)
            item.isMobile = 'Y' // 모바일 사용여부
            item.rlnmCnfRsltCd = (item.rlnmCnfRsltCd === null || item.rlnmCnfRsltCd.trim() === '') ? '' : item.rlnmCnfRsltCd
            item.knbBackup = item.knb

            if (lv_Vm.$commonUtil.isMobile()) {
              item.knbBknoCipher = item.knb.substring(6)
            } else {
              item.knbBkno = item.knb.substring(6)
            }
          }); // forEach ::End

        // 정보활용동의 리스트가 있는 경우 기존 세대원리스트VO(existHoshInfoListInqrVO) 각각에 에 정보활용동의 VO 개체를 추가함.
        // 기존 세대원리스트VO에 mcnstYmd, mktYmd, iCCustInfoPrcusCnsntVO 필드를 추가하고 각각 해당 정보를 매핑함.
        if (lv_Vm.hoshApvrList.length > 0) {
          lv_Vm.existHoshInfoListInqrVO.forEach(item => {
              let tmpVal = lv_Vm.hoshApvrList.filter(value => {
                  if (item.chnlCustId === value.chnlCustId) {
                    return value
                  }
                }); // filter 끝

              if (tmpVal.length > 0) { // 채널고객ID가 있는 경우 동의 완료일자 세팅
                item.iCCustInfoPrcusCnsntVO = tmpVal[0]
                item.mcnstYmd = (tmpVal[0].infoPrcusCnsntYmd14 !== '' && tmpVal[0].infoPrcusCnsntYmd14 !== '00000000') ? this.$commonUtil.dateDashFormat(tmpVal[0].infoPrcusCnsntYmd14) : '' // 필수 컨설팅 만료일
                item.mktYmd = (tmpVal[0].infoPrcusCnsntYmd12 !== '' && tmpVal[0].infoPrcusCnsntYmd12 !== '00000000') ? this.$commonUtil.dateDashFormat(tmpVal[0].infoPrcusCnsntYmd12) : '' // 마케팅 만료일
                item.unAprvCustYn = tmpVal[0].unAprvCustYn
              }

              // 세대원 리스트가 있는 경우 isExistCustYN 기존(Y)으로 세팅
              lv_Vm.custCardVO.isExistCustYN = 'Y' // 기존으로 세팅됨.
            }); // forEach ::End

        }

        this.fn_Validation()
      }
    },
    /******************************************************************************
     * @function fn_AgreeCnfrmPop
     * @name     이용동의_안내
    ******************************************************************************/
    fn_AgreeCnfrmPop () {
      const lv_Vm = this

      this.$refs.agreeCnfrmPop.close()
      this.isPhoneConf = false
      
      this.$nextTick(() => {
        lv_Vm.$refs['celnoRef'].focus()
      });

    },
    /******************************************************************************
     * @function fn_existCustCnfrmPop
     * @name     기존고객_휴대폰번호_변경불가_안내
    ******************************************************************************/
    fn_existCustCnfrmPop () {
      this.$refs.existCustCnfrmPop.close()
    },
    /******************************************************************************
     * @function fn_Next
     * @notice   다음으로 진행 (고객 상세정보 입력)
     *              - OPEN(컨펌팝업열기), Cancel(창닫기), Confirm(창닫기) 선택에 대한 화면 이동
    ******************************************************************************/
    fn_Next () {
      if (this.chkNextBtn) {
        this.$refs.vWatcher.validate()
        return
      }
      if (this.custCardVO.isExistCustYN === 'Y' && this.custCardVO.custNm !== this.lv_CustNm) {
        this.isCustNmChngRslt = false
        this.$refs.vWatcher.validate()
        return
      }

      // 기존고객 && 필컨N 고객일 경우
      if (this.custCardVO.isExistCustYN === 'Y' && this.custCardVO.mndtCnstgCnsntYn === 'N') { // 기존 고객일 경우 비활성화
        // 일련번호X 경우 (다음 스텝 진행 불가)
        if (this.$bizUtil.isEmpty(this.custCardVO.cnsltWcnstPblNo)) {
          let props = {
            msg: '일련번호 조회를 진행해주세요.'
          }
          this.$cmUtil.fn_OpenAlert(this, props)
          return
        }
      }
      this.isCustNmChngRslt = true
      this.$refs.vWatcher.validate()
      this.fn_SetCustInfo()

      let params = {
        custCardVO: this.custCardVO,
        agreeTypeMcnstList: {
          agreeTypeMcnst: this.custCardVO.infoPrcusMcnstYn, // 필컨 동의
          agreeTypeMkt: this.custCardVO.infoPrcusMktYn, // 마케팅 동의
          infoPrcusCnsntPathCd: this.custCardVO.infoPrcusCnsntTypCd
        }
      }

      let isChange = false
      if (this.custCardVO.isCelnoChanged || this.custCardVO.isKnbChanged) isChange = true
      if (this.custCardVO.isExistCustYN === 'N' || !isChange) {
        this.$router.push({name: 'MSPCM043M', params: params})
        return
      }
      
      // 정보 변경시, 안내 확인 후 진행 가능
      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: ' ',
          content: '고객님의 정보가 변경되었습니다.<br>동의처리 요청시 고객에게 정보 변경알림톡이 실시간 발송되었으니 주의 부탁드립니다.<br>진행하시겠습니까?',
        },
        listeners: {
          onPopupConfirm: () => {
            // 다음으로 진행
            this.$router.push({name: 'MSPCM043M', params: params})
            this.$bottomModal.close(alertObj)
          },
          onPopupClose: () => {
            this.$bottomModal.close(alertObj)
          },
        }
      })
      
    },
    /******************************************************************************
     * @function fn_SetCustInfo
     * @name     고객_정보_셋팅
     * @notice   다음 화면으로 이동시, 전달될 고객 정보 셋팅
     *           knbBknoCipher, 채널고객구분코드, 성별코드
    ******************************************************************************/
    fn_SetCustInfo () {
      // 주민번호 뒷번호 첫자
      let lv_KnbBkno = this.custCardVO.knbBkno.substr(0,1)
      this.custCardVO.knbBkFstNo = lv_KnbBkno

      if (!this.fn_IsChnlCustScCd) {
        if (lv_KnbBkno === '1' || lv_KnbBkno === '2' || lv_KnbBkno === '5' || lv_KnbBkno === '6') {
          this.custCardVO.ralDob = '19' + this.custCardVO.knbFrno
        } else {
          this.custCardVO.ralDob = '20' + this.custCardVO.knbFrno
        }
      } else { // 미확보 - 생년월일 세팅 (외국인은 미확보 불가)
        let year = this.custCardVO.knbFrno.substr(0,2)
        if (year === '19') {
          lv_KnbBkno = this.rdoGender === '1' ? '1' : '2'
        } else {
          lv_KnbBkno = this.rdoGender === '1' ? '3' : '4'
        }
        
        this.custCardVO.ralDob = this.custCardVO.knbFrno
      }

      if (this.fn_IsChnlCustScCd) { // 미확보일 경우
        this.custCardVO.knbBkno = lv_KnbBkno + '000000'
        this.custCardVO.knbFrno = this.custCardVO.knbFrno.substring(2)
        this.custCardVO.knb = this.custCardVO.knbFrno + this.custCardVO.knbBkno
      }

      // 채널고객구분코드 세팅 
      if (this.custCardVO.isExistCustYN === 'N') {
        if (this.fn_IsChnlCustScCd) { // 미확보
          this.custCardVO.chnlCustScCd = '02' // 미확보 주민번호
          this.custCardVO.knbBackup = this.fn_KnbFrno + this.custCardVO.knbBknoCipher
          this.custCardVO.knbBkFstNo = lv_KnbBkno // 성별코드
        } else {
          this.custCardVO.chnlCustScCd = '01' // 확보 주민번호
        }
      }
      
      // 휴대번호 셋팅
      this.custCardVO.celno = this.custCardVO.celnoFrno + '-' + this.custCardVO.celnoBkno.substr(0,4) + '-' + this.custCardVO.celnoBkno.substr(4)

      this.custCardVO.knbScCd = !this.isForeigner ? 'ZPER01' : 'ZPER02'
    },
    /******************************************************************************
     * @function fn_BottomSheet
     * @notice   휴대번호 수정/입력 시도 할때, 안내성 팝업 Open
    ******************************************************************************/
    fn_BottomSheet () {
      this.$refs.vWatcher.clearValidation()

      if (this.custCardVO.isExistCustYN === 'Y') {
        this.$refs.existCustCnfrmPop.open()
      } else if (this.isPhoneConf) {
        this.$refs.agreeCnfrmPop.open()
      }

      this.fn_Validation()
    },
    /******************************************************************************
     * @function fn_Validation
     * @notice   생년월일 / 휴대폰 번호 유효성 체크
    ******************************************************************************/
    fn_Validation () {
      this.lv_ValiRrnFomat = this.$cmUtil.isSuitaDate(this.fn_KnbFrno) // 생년월일 체크
      let chkKnbLen = this.fn_IsChnlCustScCd ? this.fn_KnbFrno.length > 7 : this.fn_KnbFrno.length > 5 // 미확보: 8자리 / default: 6자리

      let valiRrn = this.fn_KnbFrno !== '' && chkKnbLen && this.lv_ValiRrnFomat
      
      // 기존고객 && 미승인 고객일 경우 수정 불가(다음 스텝 진행 불가)
      if (this.custCardVO.isExistCustYN === 'Y' && this.custCardVO.unAprvCustYn === 'Y') { // 기존/미승인 고객일 경우 비활성화
        this.chkNextBtn = true
        return
      }

      if (this.custNmResult && this.custCardVO.isExistCustYN !== '' && valiRrn && this.chkCelnoResult) {
        this.chkNextBtn = false
      } else {
        this.chkNextBtn = true
      }
    },
    fn_OpenAlert (alertMsg, focusObj, callback) {
      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          content: alertMsg
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(alertObj)
            if (!_.isEmpty(focusObj)) {
              focusObj.focus()
            }
            if (callback) {
              callback()
            }
          }
        }
      })
    },
    fn_ClickPrev () {
      let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
      window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt-1)
      this.$router.go(-1)
    }
  } // ::Methods End
};
</script>
